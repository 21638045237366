<template>
    <div class="dating-status-check">
        <StackRouterHeaderBar :leftButton="'close'" />

        <main class="main">
            <FeedbackTop :title="title" :description="'상대방과 주선자에게 공개되지 않아요'" @seeProfile="seeProfile" />
            <ul class="items">
                <li class="item m-b-12" v-for="item in items" :key="item.code" @click="onClickItem(item)">
                    <p v-html="item.label" />
                    <i class="material-icons">chevron_right</i>
                </li>
            </ul>
        </main>

        <footer class="footer">
            <FeedbackDescription />
        </footer>
    </div>
</template>

<script>
import FeedbackTop from './components/FeedbackTopComponent'
import FeedbackDescription from './components/FeedbackDescription'

export default {
    name: 'DatingStatusCheckPage',
    components: { FeedbackTop, FeedbackDescription },
    props: {
        user: {
            type: Object,
            required: true,
        },
        photos: Array,
        dating: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        items: [
            {
                code: 'reject',
                label: '더 이상 연락하지 않아요',
            },
            {
                code: 'contact',
                label: '아직 만나지 않았고,<br />연락하고 있어요',
            },
            {
                code: 'meet',
                label: '만났어요',
            },
        ],
    }),
    computed: {
        title() {
            return `<p><img class="avatar m-r-8" src="${this.photos[0].url}" />${this.$nameOrNick(
                this.user,
            )}님과 소개팅,</p>
                <p>어떻게 되어가고 있나요?</p>`
        },
    },
    methods: {
        onClickItem(item) {
            if (item.code === 'reject') {
                this.$stackRouter.push({ name: 'CommunicationFeedbackPage' })
            } else if (item.code === 'meet') {
                this.$stackRouter.push({
                    name: 'DatingFeedbackPage',
                    props: {
                        user: this.user,
                    },
                })
            } else if (item.code === 'contact') {
                this.$modal
                    .basic({
                        title: 'MODAL_DEFAULT_TITLE',
                        body: '소개팅 피드백은 연락이 끊기거나 이미 만난 경우에 가능해요',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(_ => {
                        this.$unregisterBackHandler('DatingStatusCheckPage')
                        this.$stackRouter.pop()
                    })
            }

            this.$store.commit('setFeedbackPayload', { code: item.code, message_id: null, from: 'my_dating' })
        },
        backHandler() {
            this.$modal
                .basic({
                    title: '정말 나가실 건가요?',
                    body: '피드백은 신뢰 가는 소개팅 문화를 만드는 데 도움이 됩니다.',
                    buttons: [
                        {
                            label: 'EXIT',
                        },
                        {
                            label: '피드백하기',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(idx => {
                    if (idx !== 1) {
                        this.$unregisterBackHandler('DatingStatusCheckPage')
                        this.$stackRouter.pop()
                    }
                })
        },
        seeProfile() {
            this.$stackRouter.push({
                name: 'UserDetailPage',
                props: {
                    userId: this.user.id,
                    from: 'DatingStatusCheckPage',
                    photos: this.photos,
                },
            })
        },
    },
    mounted() {
        this.$store.dispatch('loadFeedbackOptions')
        this.$store.commit('setFeedbackDating', this.dating)
        this.$registerBackHandler(this.backHandler, 'DatingStatusCheckPage')
    },
    beforeDestroy() {
        this.$unregisterBackHandler('DatingStatusCheckPage')
    },
}
</script>

<style lang="scss" scoped>
.dating-status-check {
    width: 100vw;
    height: 100vh;

    .main {
        padding: 0 16px;

        .item {
            display: flex;
            justify-content: space-between;
            padding: 14px 16px;
            border-radius: 12px;
            background-color: $grey-01;
            color: black;
            font-size: 18px;

            @include f-medium;

            .material-icons {
                color: $grey-04;

                @include center;
            }
        }
    }

    .footer {
        position: absolute;
        left: 16px;
        bottom: 40px;
    }
}
</style>
